<script lang="ts">
import { getProjectMeta } from '~/util'

export const project = defineProject({
  slug: 'leaderforum',
  primary_color: '#0460A9',
  secondary_color: '#EC9A1E',
  header: {
    background: {
      image: `/projekt/leaderforum/elbworx-projekt-leaderforum-header-2464.webp`,
      black_tint_off: true,
    },
    project_class: 'text-elbworx-black',
    headline_class: 'text-elbworx-black',
    project: 'Pharma Event',
    headline: 'Leaderforum',
    info_card: {
      description:
        'Wie inspiriert man Führungskräfte? Mit fesselnden Geschichten und kraftvollen Bildern, die im Gedächtnis haften bleiben.',
      client: 'e-team Veranstaltungsorganisation',
      roles: ['Veranstaltungsdesign', 'Interne Kampagne', 'Pharma-Marketing'],
      project_duration: 'Mai 2019 bis November 2019',
    },
  },
})
</script>

<script setup lang="ts">
const { projectPath } = getProjectMeta()
</script>

<template>
  <Project :project="project">
    <template #header_text>
      <h3>Herausforderung</h3>

      <p>
        Das Leaderforum war eine Veranstaltungsreihe für Führungskräfte eines global tätigen
        Pharma-Unternehmens. In Kooperation mit unseren Münchner Event-Kolleg:innen haben wir das
        visuelle Erscheinungsbild der Serie maßgeblich mitgestaltet: Unter dem Motto „Re-energize“
        stand im Herbst 2019 das Thema Nachhaltigkeit auf der Agenda. Sowohl inhaltlich als auch
        visuell baute das Forum auf der vorherigen Tagung auf, sodass auch diesmal ein Astronaut —
        als Verkörperung von Mut und Pioniergeist — stilbildend war. Während bei der Juni-Tagung das
        „Pflanzen“ im Mittelpunkt stand, rückten wir im November das Licht als Energiequelle in den
        Vordergrund: Die nunmehr ausgewachsene Pflanze wird der Sonne hingehalten und (passend zum
        Motto) re-energetisiert. Ein kraftvolles Bild für Menschen, die in Verantwortung stehen!
      </p>
    </template>

    <ImageSwiper
      :space-between="12"
      :images="[
        {
          url: `${projectPath}elbworx-projekt-leaderforum-zitat-3500_BILDSCHÄRFE_PRÜFEN.webp`,
          alt: 'Astronaut',
        },
        {
          url: `${projectPath}elbworx-projekt-leaderforum-keyvisual-3500.webp`,
          alt: 'Astronaut mit Pflanze',
        },
        {
          url: `${projectPath}elbworx-projekt-leaderforum-keyvisual2-3500.webp`,
          alt: 'Astronaut vor Sternen',
        },
      ]"
    />

    <Padding class="mt-8">
      <Grid class="items-end">
        <div class="col-span-4">
          <Image
            class="w-full object-cover"
            :src="`${projectPath}elbworx-projekt-leaderforum-armstrong1-960.webp`"
          />
        </div>
        <div class="col-span-8">
          <Image
            class="w-full"
            :src="`${projectPath}elbworx-projekt-Leaderforum-Armstrong2-1920.webp`"
          />
        </div>
      </Grid>
    </Padding>
    <Grid class="items-start">
      <div class="col-span-7">
        <Image
          class="w-full object-cover"
          :src="`${projectPath}elbworx-projekt-leaderforum-teilnehmerausweise-1920.webp`"
        />
      </div>
      <div class="col-span-5 flex items-center justify-center">
        <video
          :src="`${projectPath}elbworx-projekt-leederforum-keyvisual-animation.mp4`"
          :poster="`${projectPath}elbworx-projekt-leaderforum-header-2464.webp`"
          muted
          class="w-full"
          autoplay
          loop
          playsinline
        />
      </div>
    </Grid>

    <Padding class="mt-20 flex justify-center md:mt-40">
      <Text
        class="!max-w-none text-end text-[1.5em]/[1] font-bold italic text-primary md:text-[2.75rem]/[1]"
      >
        <p class="!mb-6">„The best way to find out if it will work is to do it.“</p>
        <p class="!mt-0">&mdash; Simon Sinek</p>
      </Text>
    </Padding>
  </Project>
</template>
